@import './bower_components/compass-mixins/lib/_compass.scss';
@import '_vars';
@import 'fonts';
@import 'settings';
@import './bower_components/foundation/scss/normalize.scss';
@import './bower_components/foundation/scss/foundation.scss';

%sprite {
    background-image: url('/imgs/imgs.png') !important;
    background-color: transparent !important;
    background-repeat: no-repeat !important;
}

%white-text {
    color: $white-text;
}

.full-width {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: initial;

    &.bordered-bottom {
        border-bottom: 1px solid $light-gray-line;
    }

    &.bordered-top {
        border-top: 1px solid $light-gray-line;
    }
}

.range-slider {
    .range-slider-active-segment {
        border-radius: 1000px;
    }

    .range-slider-handle {
        @extend %sprite;
        background-position: -64px -1px !important;
    }
}

button,
.button {
    background-image: -owg(linear-gradient($top-gradient, $bottom-gradient));
    background-image: -webkit(linear-gradient($top-gradient, $bottom-gradient));
    background-image: -moz(linear-gradient($top-gradient, $bottom-gradient));
    background-image: -o(linear-gradient($top-gradient, $bottom-gradient));
    background-image: linear-gradient($top-gradient, $bottom-gradient);
    //@include background-image($top-gradient, $bottom-gradient); // Доделать после пересборки    border-bottom: 2px solid $border-bottom;
    border-bottom: 2px solid $border-bottom;
    text-shadow: 0 1px 0 $text-shadow;

    &.disabled,
    &[disabled] {
        border-color: $border-bottom;
    }

    &.secondary {
        background-color: $second-style-button-bg;
        border: 1px solid $gray-line;
        background-image: none;
    }

    .flag {
        margin-bottom: 2px;
    }
}

.gray-bg {
    background-color: $slide-gray-bg;
}

h1 {
    margin-bottom: 55px;
}

.v-align {
    display: inline-block;
    height: 100%;
    min-height: 100%;
    width: 0;
    vertical-align: middle;
    font-size: 0;
    line-height: 0;
}

.tooltip-focus-tip {
    max-width: 220px;
}

.lie-link {
    display: inline-block;
    color: $blue-link-color;

    span {
        cursor: pointer;
        border-bottom: 1px dashed $blue-link-color;
    }
}

.small-margin-bottom {
    input,
    .inline,
    button,
    .button {
        margin-bottom: rem-calc(3px);
    }
}

.text-small {
    font-size: .9em;
}

.text-smaller {
    font-size: .8em;
}

.text-opacity08 {
    opacity: .8;
}

.flag {
    background: transparent url('/imgs/flags.png') 0 0 no-repeat;
    display: inline-block;
    vertical-align: baseline;
    width: 16px;
    height: 9px;
    font-size: 0;
    line-height: 0;
    margin: 0;
    padding: 0;

    &.flag-ru {
        background-position: 0 -1764px;
    }

    &.flag-by {
        background-position: 0 -324px;
    }

    &.flag-kz {
        background-position: 0 -1161px;
    }

    &.flag-ua {
        background-position: 0 -2115px;
    }
}

// шапка
.logo {
    padding: 9px 0 14px;
    display: inline-block;
    text-align: left;

    img {
        width: 176px;
        height: 41px;
    }

    .slogan {
        color: $light-gray;
        margin: 0 0 0 49px;
        line-height: 1.1 !important;
        font: rem-calc(11.2px) $roboto-reg, serif;
    }
}

.main-menu {
    margin-top: 27px;

    li {
        margin-left: rem-calc(7px);
    }

    li:first-child {
        margin-left: 0;

        .menu-item {
            border-left: none;
        }
    }

    .menu-item {
        display: block;
        padding: 1px 15px 1px 20px;
        border-left: 1px solid $light-gray-line;
        color: $dark-gray;
        font: rem-calc(14px) $sans, sans-serif;

        &:hover {
            color: $hover-gray-link;
        }
    }
}


// блок для привлечения внимания
.try-btn {
    margin-bottom: 5px;
}

.try-period {
    font: rem-calc(13px) $sans, sans-serif;
}

.hero-bg {
    background: url('/imgs/pattern.png') 0 0 repeat;

    .hero {
        padding: 50px 0 20px;
    }

    .try-period {
        color: $light-brown;
    }

    .main-text {
        @extend %white-text;
        line-height: 1 !important;
        font: bold rem-calc(42px) $roboto, serif;
        margin: 18px 0 0;
    }

    .second-text {
        @extend %white-text;
        font-weight: normal;
        margin: 10px 0 38px;
        font: rem-calc(26px) $sans, sans-serif;
    }
}

// footer
footer {
    hr {
        margin: 0;
    }

    .copyright {
        padding: 22px 0;
    }
}

// modals
.reveal-modal {

    &.small-plus {
        width: 50%;
    }

    .modal-header {
        font-size: rem-calc(30px);
        text-align: center;
        line-height: 1.1;
        margin-bottom: 30px;
    }

    .close-reveal-modal {
        font-family: $sans, sans-serif;
        font-weight: normal;
    }

    .ok-arrow {
       display: block;
        max-width: 50%;
        margin: 0 auto;
    }
}

// errors
.error-registration {
    &.hide {
        display: none !important;
    }
}

// content
.slide-box {
    padding: 45px 0;
}

.why-num {
    @extend %white-text;
    padding-top: 12px;
    width: 86px;
    height: 86px;
    border-radius: 43px;
    text-align: center;
    background-color: $why-dot-bg;
    border: 18px solid $white-line;
    margin: 0 auto;
    font: bold rem-calc(18px) $roboto, serif;
}

.why-dot-line {
    margin: 0 0 -43px;
}

.courier-app-dot {
    font-family: $roboto, serif;
    line-height: 1.3;

    .pict {
        @extend %sprite;
        width: 115px;
        height: 115px;
        margin: 0 auto 33px;

        &.chat {
            background-position: 0 -150px;
        }

        &.barcode {
            background-position: -358px -150px;
        }

        &.status {
            background-position: -239px -151px;
        }

        &.maps {
            background-position: -119px -150px;
        }
    }
}

@mixin text-bg {
    @extend %white-text;
    position: relative;
    padding: 3px 0;
    display: inline;
    background-color: $why-dot-bg;
}

.text-bg-outer {
    padding-left: 7px;
    line-height: 0;
    margin-bottom: 10px;

    .text-with-bg {
        @include text-bg;
        left: -7px;

        .level-1 {
            @include text-bg;
            left: 15px;
        }

        .level-2 {
            @include text-bg;
            left: -7px;
            padding: 0;
        }
    }
}

.header-features {
    font: rem-calc(16px) $roboto, serif;
}

.list-features {
    font-size: rem-calc(13px);
    line-height: 1.8;
}

.clients {
    text-align: center;

    li {
        height: 80px;
    }

    .client {
        @extend %sprite;
        display: inline-block;
        vertical-align: middle;

        &.b2c {
            width: 148px;
            height: 31px;
            background-position: -78px -104px;
        }

        &.lenod {
            width: 74px;
            height: 64px;
            background-position: 0 -82px;
        }

        &.emex {
            width: 160px;
            height: 53px;
            background-position: -286px -1px;
        }

        &.zenit {
            width: 130px;
            height: 63px;
            background-position: -152px -36px;
        }

        &.ddelivery {
            width: 145px;
            height: 43px;
            background-position: 0 -36px;
        }
    }
}

.slider {
    .column,
    .columns {
        padding-top: 10px;
    }
}

.prices-box {
    h1 {
        margin-bottom: 45px;
    }

    .lead {
        margin-bottom: 46px;
    }

    .panel {
        margin-bottom: 40px;
    }

    .headers {
        font-family: $roboto, serif;
    }

    .total-price {
        color: $red-text;
        font-weight: normal;
        font: rem-calc(60px) $sans-reg, sans-serif;
        line-height: 1.1;
    }

    .try-period {
        color: $super-light-gray;
    }
}

.contacts-map {
    height: 450px;
}

.balloon-box {
    width: 380px;
    text-align: center;
    color: $def-text;
    font: rem-calc(16px) $sans, sans-serif;

    h1 {
        margin-bottom: 30px;
    }

    .contacts-ico {
        @extend %sprite;
        display: inline-block;

        &.phone {
            width: 32px;
            height: 28px;
            background-position: -26px 0;
        }

        &.point {
            width: 20px;
            height: 32px;
            background-position: 0 0;
        }
    }
}

.price-scale {
    position: relative;
    height: 30px;

    .scale-item {
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;

        &.active {
            font-weight: bold;
        }
    }
}

.help-text {
    color: $help-text;

    h5 {
        color: $help-text;
        font-size: rem-calc(12px);
    }

    p {
        font-size: rem-calc(10px);
    }
}

.balloon-layout {
    background-color: $white-bg;
    width: 400px;
    padding: 20px 20px 30px;
    position: absolute;

    .balloon-close {
        position: absolute;
        right: 10px;
        top: 2px;
        color: $close-text;
        line-height: 1;
        font-size: rem-calc(40px);
    }

    .arrow {
        $wb: 50px;
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -$wb;
        height: 0;
        width: 0;
        border: 20px solid transparent;
        border-left-width: $wb;
        border-right-width: $wb;
        border-top-color: $white-bg;
    }
}

.recall-code {
    opacity: .5;

    &.hide {
        display: none;
    }

    &.active {
        opacity: 1;

        .counter-code {
            display: none;
        }
    }
}

.select-country {
    width: auto;

    li {
        white-space: nowrap;
    }
}

.register-step-1 {

    label, input {
        margin-bottom: 0;
    }

    label {
        padding: 0;
    }

    .domain-help {
        font-size: .85em;
        display: block;
        line-height: 1.2;
        margin-bottom: 15px;

        span {
            font-style: normal;
        }
    }
}

@media #{$small-only} {
    h1 {
        margin-bottom: 25px;
    }

    .slide-box {
        padding: 30px 0;
    }

    .logo {
        padding-bottom: 0;
    }

    .main-menu {
        margin-top: 11px;
    }

    .hero-bg {
        .main-text {
            font-size: rem-calc(35px);
        }

        .second-text {
            font-size: rem-calc(20px);
        }
    }

    .client {
        margin: 0 auto;
    }

    .prices-box {
        h1 {
            margin-bottom: 25px;
        }

        .total-price {
            font-size: rem-calc(50px);
        }
    }

    .balloon-box {
        width: 100%;
        font-size: rem-calc(13px);

        h1 {
            margin-bottom: 15px;
            font-size: rem-calc(21px);
        }
    }

    .why-box {
        li {
            font-size: rem-calc(16px);
        }
    }

    .reveal-modal {
        &.small-plus {
            width: 100%;
        }
    }
}

@media #{$large-up} {
    .contacts-map {
        height: 700px;
    }
}
