// text colors
$white-text: #fff;
$def-text: #555;
$light-gray: #919191;
$dark-gray: #3e3e3e;
$hover-gray-link: #6d6d6d;
$light-brown: #9f8d83;
$red-text: #d6523c;
$super-light-gray: #777;
$help-text: #9ba3a4;
$close-text: #aaa;
$blue-link-color: #008cba;

// fonts
$sans-reg: 'OpenSans-Regular';
$sans: 'OpenSans';
$roboto-reg: 'RobotosLab-Regular';
$roboto: 'RobotosLab';
$roboto: 'RobotosLab';
$proxima: 'ProximaNova';

// lines
$light-gray-line: #ebebeb;
$white-line: #fff;
$gray-line: #ccc;


// buttons
$top-gradient: #ffd678;
$bottom-gradient: #fcb812;
$border-bottom: #e1a000;
$text-shadow: #fdd26d;

//backgrounds
$why-dot-bg: #fcb80f;
$slide-gray-bg: #f5f5f5;
$white-bg: #fff;
$second-style-button-bg: #e7e7e7;
